<template lang="pug">
div(style="min-height: 70vh")
  v-card.pa-5(min-height="420")
    div.d-flex.justify-center
      v-card.ml-auto.pr-3(
        v-if="$vuetify.breakpoint.xs"
        tile,
        max-width="200"
        flat
      )
        img.rounded-circle.ml-auto.pa-3.mr-n5(
          :src="require(`@/assets/institutes/${institute.image}`)",
          height="100px"
        )
      v-card.ml-auto.pr-3(
        style="border-right: 2px solid #010073",
        tile,
        flat
        max-width="200"
        v-else
      )
        img.rounded-circle.ml-auto(
          :src="require(`@/assets/institutes/${institute.image}`)",
          height="100px"
        )
      v-card.mr-auto.pl-3(max-width="800", color="transparent", flat).d-flex.align-center
        .mr-1.font-weight-light.display-1.text-uppercase.text-start(v-if="$vuetify.breakpoint.mdAndDown") {{ institute.acronym }}
        .mr-1.font-weight-light.display-1.text-uppercase.text-start(v-else) {{ institute.name }}
    v-dialog(v-model="refDialog" max-width="800")
      v-card
        v-card-title.headline.grey.lighten-2 {{course}}
        div.pa-7.pb-3
          div.font-weight-bold.subtitle-2 References
          v-list-item-group(v-if="reference.length>0")
            v-list-item(v-for="(v, key) in reference" :key="key" @click="link(v.split('(#link)')[1])")
              v-list-item-icon
                v-icon mdi-arrow-right-bottom-bold
              v-list-item-action.text-muted.mr-1 Ref. {{key+1}}:
              v-list-item-content {{v.split('(#link)')[0]}}
          v-list-item-content(v-else).text-muted.body-2.ml-10 No listed references
        v-divider
        v-card-actions.px-5.pb-5
          v-spacer
          v-btn(color="grey", text, @click="refDialog=false") Close
    ais-instant-search(
    :search-client="searchClient",
    index-name="courseReferences",
    :routing="routing"
    :stalled-search-delay="200"
    ).pa-5
      ais-configure(
       v-bind="searchParameters"
       :query="search"
    )
      v-row(justify="center" no-gutters)
        v-col(cols="8" md="8").pr-2
          v-text-field(
            v-model.trim="search"
            rounded
            filled
            dense
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            ).mt-3.mb-n2
        v-col(cols="4" sm="3" md="2")
          v-select(
            v-model="mode"
            :items="modeItem"
            rounded
            filled
            dense
            ).mt-3.mb-n2
      ais-hits(ref="aisHit")
          div(slot-scope="{ items }") 
            v-row(v-if="mode=='Tile'").mx-auto
              v-col(v-for="item in items", :key="item.id", md="6", lg="4", sm="6" cols="12")
                v-card(
                  class="mx-auto"
                  outlined
                  max-width="400"
                  ).pa-3
                  v-card-title 
                    span.text-h6.font-weight-bold {{item.course}}
                  v-alert(
                    border="left"
                    text
                    dense
                    :color="institute.color"
                    ).font-weight-medium {{item.description}}
                  v-card-actions
                    v-btn(small rounded color="primary" @click="refOpen(item.ref, item.course)") Open
                    v-spacer
                    v-icon.ml-4 mdi-link
                    span.subtitle.ml-1 {{item.ref.length}}
            v-list(v-else)
              v-list-group(
                v-for="item in items"
                :key="item.id"
                :color="institute.color"
                )
                template(v-slot:activator)
                  v-list-item-content
                    v-list-item-title.font-weight-medium.d-flex
                      div {{item.course}}
                      div.ml-2.font-weight-regular(v-if="$vuetify.breakpoint.mdAndUp") {{item.description}}
                      div.ml-auto
                        v-icon(small).ml-4 mdi-link
                        span.caption.ml-1 {{item.ref.length}}
                div.font-weight-bold.subtitle.ml-4(v-if="$vuetify.breakpoint.smAndDown") {{item.description}}
                div.ml-8.font-weight-bold.subtitle-2 References
                v-list-item-group(v-if="item.ref.length>0")
                  v-list-item(v-for="(v, key) in item.ref" :key="key" @click="link(v.split('(#link)')[1])")
                    v-list-item-icon
                      v-icon mdi-arrow-right-bottom-bold
                    v-list-item-action.text-muted.mr-1 Ref. {{key+1}}:
                    v-list-item-content {{v.split('(#link)')[0]}}
                v-list-item-content(v-else).text-muted.body-2.ml-10 No listed references
      ais-pagination
          div(
            slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}"
          )
            .text-center.mt-5
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isFirstPage",
                @click.prevent="refine(0); toTop()",
                outlined
              )
                v-icon mdi-chevron-double-left
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                @click.prevent="refine(currentRefinement - 1); toTop()",
                outlined
              )
                v-icon mdi-menu-left
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-for="page in pages.slice(0,4)",
                :key="page",
                :outlined="page != currentRefinement",
                @click.prevent="refine(page); toTop()"
              ) {{ page + 1 }}
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isLastPage",
                @click.prevent="refine(currentRefinement + 1); toTop()",
                outlined
              )
                v-icon mdi-menu-right
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isLastPage",
                @click.prevent="refine(nbPages); toTop()",
                outlined
              )
                v-icon mdi-chevron-double-right                      
</template>

<script>
import syllabi from "@/syllabi.js";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { history } from "instantsearch.js/es/lib/routers";
import { simple } from "instantsearch.js/es/lib/stateMappings";

export default {
  data() {
    return {
      value: this.$route.params.slug,
      limit: 12,
      searchClient: null,
      searchParameters: {
        hitsPerPage: 12,
        facetFilters: ["institute:" + this.$route.params.slug],
      },
      routing: {
        router: history(),
        stateMapping: simple(),
      },
      search: "",
      reference: [],
      refDialog: false,
      course: "",
      mode: "List",
      modeItem: ["List", "Tile"],
    };
  },

  computed: {
    institute() {
      return syllabi.institutes.find(
        (institute) => institute.name === this.value
      );
    },
  },

  async created() {
    this.collection();
  },

  methods: {
    refOpen(ref, course) {
      this.reference = ref;
      this.course = course;
      this.refDialog = true;
    },
    link(link) {
      if (link == " ") {
        this.$store.dispatch("setSnackbar", {
          text: "  Cannot be redirected. No specific link specified!",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
        return;
      }
      window.open(link, "_blank");
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    collection() {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: "UClwVI81Eboli8TAnJdUv95cAoZkPCY9", // Be sure to use an API key that only allows search operations
          nodes: [
            {
              host: "ehubstorage.cslib.upd.edu.ph",
              port: "443",
              protocol: "https",
            },
          ],
          cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
        additionalSearchParameters: {
          queryBy: "course, description",
          sortBy: "_text_match:desc, number:asc",
        },
      });
      this.searchClient = typesenseInstantsearchAdapter.searchClient;
    },
  },
};
</script>

<style scoped>
.border {
  border: 5px solid red;
}
</style>
